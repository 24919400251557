import React, { Suspense, useContext, useEffect, useState } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useTranslation } from "react-i18next"
// import {ApolloProvider} from "@apollo/react-hooks";
import { ApolloProvider } from '@apollo/client';
import { Switch, BrowserRouter, Route, useLocation } from 'react-router-dom';

import { AuthWrapper, AuthTypeEnum } from "components/AuthWrapper";
import { Menu } from "components/front/APP/Navigation/Menu/Menu"
import {

  URL_PUBLIC_PAYMENT_3DSECURE,

  URL_PUBLIC,
  URL_CRM_SEGMENTATION_ADD,
  URL_BACKOFFICE_USER_ADD,
  URL_BACKOFFICE_USER_LIST,
  URL_USER_CONFIG_LIST,
  URL_CUSTOMER_DETAIL,
  URL_FRONT, URL_GRAPHQL,
  URL_SEARCH,
  URL_CUSTOMER_LIST,
  URL_CUSTOMER_LIST_PARAMS,
  URL_CRM_SEGMENTATION_LIST,
  URL_COM_CONTACT_LIST_EDIT,

  URL_CONTACT_DEDUP_LIST,

  URL_CONFIRMATION_LIST,

  URL_PRE_STAY_LIST,
  URL_PRE_STAY_EDIT,

  URL_RESERVATION_DETAILS,
  URL_RESERVATION_SEARCH,

  URL_PROPOSITION_PUSH,
  URL_PROPOSITION_EDIT,
  URL_PROPOSITION_ADD,

  // URL_PUBLIC_RESERVATION_DETAILS,
  URL_PUBLIC_CONFIRMATION_DETAILS,
  URL_BACKOFFICE_HOTEL_LIST,
  URL_BACKOFFICE_HOTEL_EDIT,
  URL_PROPOSITION_LIST,
  URL_SEARCH_QUERY,
  URL_BACKOFFICE_PAYMENT_ADD,
  URL_BACKOFFICE_PAYMENT_EDIT,
  URL_BACKOFFICE_PAYMENT_LIST,
  URL_PLANNING_VIEW,

  URL_PLANNING_ADD,
  URL_PLANNING_LIST,
  URL_PUBLIC_PROPOSITION_DETAILS,
  URL_BACKOFFICE_TEMPLATE_ADD_ROUTE,
  URL_BACKOFFICE_TEMPLATE_EDIT_ROUTE,
  URL_BACKOFFICE_TEMPLATE_LIST_ROUTE,
  URL_PUBLIC_PAYMENT_3DSECURE_ID,
  URL_PUBLIC_PRE_STAY_ANSWER,

  URL_POST_STAY_LIST,
  URL_POST_STAY_DISPLAY_ID,

  URL_PUBLIC_POST_STAY_ANSWER,
  URL_PLANNING_EVENT_LIST,
  URL_PLANNING_EVENT_EDIT,
  URL_PHONE_CALL_LIST,
  URL_PHONE_CALL_EDIT,
  URL_PHONE_CALL_LIST_PAGE,
  URL_PHONE_CALL_EDIT_PAGE,
  URL_PHONE_DIRECTORY_ID,
  URL_PHONE_DIRECTORY,
  URL_RESERVATION_GROUP_LIST,
  URL_PHONE_CONFIG,
  URL_NETWORK_CONFIG,
  URL_NETWORK_CONFIG_HOTELID,
  URL_PHONE_CONFIG_ID,
  URL_PHONE_CALL_LIST_ID,
  // URL_BACKOFFICE_HOTEL_EDIT_FOCUS,

  URL_CORP_LIST,
  URL_POST_STAY_DASHBOARD,
  URL_POST_STAY_DASHBOARD_ID,

  URL_VIDEO_CAMERA_LIST,
  URL_VIDEO_CAMERA_DISPLAY,
  URL_NEWS_LIST,

  URL_BACKOFFICE_BILLING_TOKEN_LIST,
  URL_BACKOFFICE_BILLING_TOKEN_ADD,
  URL_BACKOFFICE_BILLING_TOKEN_EDIT,
  URL_PUBLIC_TOKEN_DISPLAY,
  URL_BACKOFFICE_BILLING_TOKEN_DISPLAY,
  URL_CHECKIN_LIST,

} from "constant/url";

// import {menuIsOpenContext} from "context/Navigation/menuIsOpen"
import { alertContext } from "context/Alert/alert"
import { Alert } from "components/common/Navigation/Alert/Alert";
import { MainRoute } from "./MainRoute";

import { graphqlPlaygroundRoute } from "./APP/graphql"
import { SearchRoute } from "./Search/SearchRoute";
import { CustomerDetailsRoute } from "./CRM/Customer/Details/CustomerDetailsRoute";
import { UserListRoute } from "./APP/User/List/UserListRoute";
import { UserConfigListRoute } from "./APP/User/Config/ConfigListRoute";
import { UserAddRoute } from "./APP/User/Add/UserAddRoute";
import { CustomerListRoute } from "./CRM/Customer/List/CustomerListRoute";
import { SegmentationAddRoute } from "./CRM/Segmentation/Edit/SegmentationAddRoute";
import { SegmentationListRoute } from "./CRM/Segmentation/List/SegmentationListRoute";
import { TemplateEditRoute } from "./COM/Template/Edit/TemplateEditRoute";
// import {TemplateListRoute} from "./COM/Template/List/TemplateListRoute";
import { ReservationDetailsRoute } from "./PMS/Reservation/ReservationDetailsRoute";
// import {CTIAgent} from "components/front/PHONE/CTI/CTIAgent";
import { ReservationSearchRoute } from "./PMS/Reservation/ReservationSearchRoute";
import { getClient } from "helpers/Apollo";
import { ConfirmationListRoute } from "./PMS/Confirmation/ConfirmationListRoute";
import { HotelListRoute } from "./PMS/Hotel/HotelListRoute";
import { PropositionEditRoute } from "./PMS/Proposition/Edit/PropositionEditRoute";
import { PropositionAddRoute } from "./PMS/Proposition/Add/PropositionAddRoute";
import { PropositionListRoute } from "./PMS/Proposition/List/PropositionListRoute";
import { PaymentAddRoute } from "./BILLING/Payment/PaymentAddRoute";
import { PaymentEditRoute } from "./BILLING/Payment/PaymentEditRoute";
import { PaymentListRoute } from "./BILLING/Payment/PaymentListRoute";

import { PlanningViewRoute } from "./PLANNING/PlanningViewRoute";
import { PlanningAddRoute } from "./PLANNING/PlanningAddRoute";
import { PlanningListRoute } from "./PLANNING/PlanningListRoute";
import { PropositionPublicDetailsRoute } from "./PUBLIC/PMS/Proposition/PropositionPublicDetailsRoute";
import { ConfirmationPublicDetailsRoute } from "./PUBLIC/PMS/Confirmation/ConfirmationPublicDetailsRoute";
import { HotelEditRoute } from "./PMS/Hotel/HotelEditRoute";
import { TemplateAddRoute } from "./COM/Template/Add/TemplateAddRoute";
import { TemplateListRoute } from "./COM/Template/List/TemplateListRoute";
import { Breadcrumb } from "components/common/Navigation/Breadcrumb/Breadcrumb";
import { PaymentConfirmIdRoute } from "./PUBLIC/BILLING/Payment/PaymentConfirmIdRoute";
import { PaymentConfirmPublicIdAndSaltRoute } from "./PUBLIC/BILLING/Payment/PaymentConfirmPublicIdAndSaltRoute";
import { SegmentationEditRoute } from "./CRM/Segmentation/Edit/SegmentationEditRoute";
import { PreStayListRoute } from "./PMS/Stay/Pre/PreStayListRoute";
import { PostStayListRoute } from "./PMS/Stay/Post/PostStayListRoute";
import { PreStayAnswerRoute } from "./PUBLIC/PMS/Stay/Pre/PreStayAnswerRoute";
import { PostStayAnswerRoute } from "./PUBLIC/PMS/Stay/Post/PostStayAnswerRoute";
import { ContactDedupListRoute } from "./PMS/Contact/ContactDedupListRoute";
import { EventListRoute } from "./PLANNING/PlanningEventListRoute";
import { PhoneCallListRoute } from "./PHONE/Call/PhoneCallListRoute";
import { ReservationGroupListRoute } from "./PMS/Reservation/Group/ReservationGroupListRoute";
import { Modal } from "components/common/Navigation/Modal/Modal";
import { PhoneConfigRoute } from "./PHONE/Config/PhoneConfigRoute";
import { CTIAgent } from "components/front/PHONE/CTI/CTIAgent";
import { NetworkRoute } from "./NETWORK/NetworkRoute";
import { NetworkRouteHotel } from "./NETWORK/NetworkRouteHotel";
import { CorpListRoute } from "./APP/Corp/CorpListRoute";
import { PhoneDirectoryRoute } from "./PHONE/Directory/PhoneDirectoryRoute";
import { PhoneSipClientWrapper } from "components/front/PHONE/Sip/Client/Wrapper/PhoneSipClientWrapper";
import { PostStayDashboardRoute } from "./PMS/Stay/Post/PostStayDashboardRoute";
import { CameraListRoute } from "./VIDEO/Camera/CameraListRoute";
import { NewsListRoute } from "./APP/News/NewsListRoute";
import { PostStayDisplayRoute } from "./PMS/Stay/Post/PostStayDisplayRoute";
import { TokenList } from "components/front/BILLING/Token/List/TokenList";
import { TokenListRoute } from "./BILLING/Token/TokenListRoute";
import { TokenAddRoute } from "./BILLING/Token/TokenAddRoute";
import { TokenEditRoute } from "./BILLING/Token/TokenEditRoute";
import { TokenConfirmPublicIdAndSaltRoute } from "./PUBLIC/BILLING/Token/TokenConfirmPublicIdAndSaltRoute";
import { TokenDisplayRoute } from "./BILLING/Token/TokenDisplayRoute";
import { ReservationCheckinRoute } from "./PMS/Reservation/ReservationCheckinRoute";
import { CheckinListRoute } from "./PMS/Checkin/CheckinListRoute";
import { CameraDisplayRoute } from "./VIDEO/Camera/CameraDisplayRoute";

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
}



export const BreadcrumbedComponent = ({ hasContainer, component, path }: { hasContainer: boolean, component: any, path: string }) => {
  return <div className={hasContainer ? "container" : "container-full"}>
    <Route path={path} component={component} exact />
  </div>
}


export const Router = (props: any) => {

  return <div className="">
    <Suspense fallback={<div>
      <Modal display><h1>Loading...</h1><span className="icon-loading"></span> </Modal>
    </div>}>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID}>
          <ScrollToTop />
          <Switch>
            <Route path={URL_PUBLIC} component={PublicContent} />
            <Route path={URL_FRONT} component={SecureContent} />
          </Switch>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Suspense>
  </div >
}


const SecureContent = (props: any) => {

  const itemMap: { [key: string]: { path?: string, hasContainer: boolean, component: any, breadcrumb?: { label: string, link?: string, active?: boolean, }[] } } = {

    // [URL_VIDEO_CAMERA_DISPLAY]: {
    //   hasContainer: false,
    //   component: CameraDisplayRoute,
    // },

    [URL_CHECKIN_LIST]: {
      hasContainer: true,
      component: CheckinListRoute,
      breadcrumb: [
        { label: "Checkin" },
        { label: "Liste", active: true },
      ]
    },
    [URL_POST_STAY_DISPLAY_ID]: {
      hasContainer: true,
      component: PostStayDisplayRoute,
      breadcrumb: [
        { label: "Post Stay" },
        { label: "Liste", link: URL_POST_STAY_LIST },
        { label: "Afficher", active: true },
      ]
    },

    [URL_POST_STAY_DASHBOARD_ID]: {
      hasContainer: true,
      component: PostStayDashboardRoute,
      breadcrumb: [
        { label: "Post Stay" },
        { label: "Dashboard", active: true },
      ]
    },
    [URL_POST_STAY_DASHBOARD]: {
      hasContainer: true,
      component: PostStayDashboardRoute,
      breadcrumb: [
        { label: "Post Stay" },
        { label: "Dashboard", active: true },
      ]
    },

    [URL_NEWS_LIST]: {
      hasContainer: true,
      component: NewsListRoute,
      breadcrumb: [
        { label: "Configuration" },
        { label: "News" },
        { label: "Lister", active: true },
      ]
    },
    [URL_VIDEO_CAMERA_LIST]: {
      hasContainer: true,
      component: CameraListRoute,
      breadcrumb: [
        { label: "Video" },
        { label: "Caméra" },
        { label: "Lister", active: true },
      ]
    },
    [URL_PROPOSITION_ADD]: {
      hasContainer: false,
      component: PropositionAddRoute,
      breadcrumb: [
        { label: "Proposition" },
        { label: "Liste", link: URL_PROPOSITION_LIST },
        { label: "Ajouter", active: true },
      ]
    },
    [URL_PROPOSITION_LIST]: {
      hasContainer: true,
      component: PropositionListRoute,
      breadcrumb: [
        { label: "Proposition" },
        { label: "Liste", active: true },
      ]
    },
    [URL_PROPOSITION_PUSH]: {
      hasContainer: true,
      component: PropositionEditRoute,
      breadcrumb: [
        { label: "Proposition" },
        { label: "Liste", link: URL_PROPOSITION_LIST },
        { label: "Editer" },
        { label: "Push", active: true },
      ]
    },
    [URL_PROPOSITION_EDIT]: {
      hasContainer: false,
      component: PropositionEditRoute,
      breadcrumb: [
        { label: "Proposition" },
        { label: "Liste", link: URL_PROPOSITION_LIST },
        { label: "Editer", active: true },
      ]
    },
    [URL_BACKOFFICE_PAYMENT_ADD]: {
      hasContainer: true,
      component: PaymentAddRoute,
      breadcrumb: [
        { label: "Paiement" },
        { label: "Liste", link: URL_BACKOFFICE_PAYMENT_LIST },
        { label: "Ajouter", active: true },
      ]
    },
    [URL_BACKOFFICE_PAYMENT_EDIT]: {
      hasContainer: true,
      component: PaymentEditRoute,
      breadcrumb: [
        { label: "Paiement" },
        { label: "Liste", link: URL_BACKOFFICE_PAYMENT_LIST },
        { label: "Editer", active: true },
      ]
    },
    [URL_BACKOFFICE_PAYMENT_LIST]: {
      hasContainer: false,
      component: PaymentListRoute,
      breadcrumb: [
        { label: "Paiement" },
        { label: "Liste", active: true },
      ]
    },
    [URL_CONFIRMATION_LIST]: {
      hasContainer: true,
      component: ConfirmationListRoute,
      breadcrumb: [
        { label: "Confirmation" },
        { label: "Liste", active: true },
      ]
    },
    [URL_CONTACT_DEDUP_LIST]: {
      hasContainer: true,
      component: ContactDedupListRoute,
      breadcrumb: [
        { label: "Contact" },
        { label: "Doublons" },
        { label: "Liste", active: true },
      ]
    },
    [URL_PRE_STAY_LIST]: {
      hasContainer: true,
      component: PreStayListRoute,
      breadcrumb: [
        { label: "Pre Stay" },
        { label: "Liste", active: true },
      ]
    },
    [URL_PRE_STAY_EDIT]: {
      hasContainer: true,
      component: PreStayListRoute,
      breadcrumb: [
        { label: "Pre Stay" },
        { label: "Liste" },
        { label: "Editer", active: true },
      ]
    },
    [URL_POST_STAY_LIST]: {
      hasContainer: false,
      component: PostStayListRoute,
      breadcrumb: [
        { label: "Post Stay" },
        { label: "Liste", active: true },
      ]
    },

    [URL_CRM_SEGMENTATION_LIST]: {
      hasContainer: true,
      component: SegmentationListRoute,
      breadcrumb: [
        { label: "Segmentation" },
        { label: "Liste", active: true },
      ]
    },
    [URL_CRM_SEGMENTATION_ADD]: {
      hasContainer: true,
      component: SegmentationAddRoute,
      breadcrumb: [
        { label: "Segmentation" },
        { label: "Liste", link: URL_CRM_SEGMENTATION_LIST },
        { label: "Ajouter", active: true },
      ]
    },
    [URL_COM_CONTACT_LIST_EDIT]: {
      hasContainer: true,
      component: SegmentationEditRoute,
      breadcrumb: [
        { label: "Segmentation" },
        { label: "Liste", link: URL_CRM_SEGMENTATION_LIST },
        { label: "Editer", active: true },
      ]
    },
    [URL_RESERVATION_GROUP_LIST]: {
      hasContainer: true,
      component: ReservationGroupListRoute,
      breadcrumb: [
        { label: "Reservation" },
        { label: "Groupe" },
        { label: "Liste", active: true },
      ]
    },
    [URL_BACKOFFICE_HOTEL_LIST]: {
      hasContainer: true,
      component: HotelListRoute,
      // breadcrumb: [
      //   { label: "Configuration" },
      //   { label: "Liste des Hotels", active: true },
      // ]
    },
    [URL_BACKOFFICE_HOTEL_EDIT]: {
      hasContainer: true,
      component: HotelEditRoute,
    },

    [URL_BACKOFFICE_TEMPLATE_LIST_ROUTE]: {
      hasContainer: true,
      component: TemplateListRoute,
      breadcrumb: [
        { label: "Template" },
        { label: "Liste", active: true },
      ]
    },
    [URL_BACKOFFICE_TEMPLATE_ADD_ROUTE]: {
      hasContainer: true,
      component: TemplateAddRoute,
      breadcrumb: [
        { label: "Template" },
        { label: "Liste", link: URL_BACKOFFICE_TEMPLATE_LIST_ROUTE },
        { label: "Ajouter", active: true },
      ]
    },
    [URL_BACKOFFICE_TEMPLATE_EDIT_ROUTE]: {
      hasContainer: true,
      component: TemplateEditRoute,
      breadcrumb: [
        { label: "Template" },
        { label: "Liste", link: URL_BACKOFFICE_TEMPLATE_LIST_ROUTE },
        { label: "Editer", active: true },
      ]
    },
    [URL_USER_CONFIG_LIST]: {
      hasContainer: true,
      component: UserConfigListRoute,
      breadcrumb: [
        { label: "User Config", active: true },
      ]
    },
    [URL_PLANNING_VIEW]: {
      hasContainer: true,
      component: PlanningViewRoute,
      breadcrumb: [
        { label: "Planning" },
        { label: "Liste", link: URL_PLANNING_LIST },
        { label: "Voir", active: true },
      ]
    },
    [URL_PLANNING_ADD]: {
      hasContainer: true,
      component: PlanningAddRoute,
      breadcrumb: [
        { label: "Planning" },
        { label: "Liste", link: URL_PLANNING_LIST },
        { label: "Ajouter", active: true },
      ]
    },
    [URL_PLANNING_EVENT_LIST]: {
      hasContainer: true,
      component: EventListRoute,
      breadcrumb: [
        { label: "Planning" },
        { label: "Evenement" },
        { label: "Liste", active: true },
      ]
    },
    [URL_PLANNING_EVENT_EDIT]: {
      hasContainer: true,
      component: EventListRoute,
      breadcrumb: [
        { label: "Planning" },
        { label: "Evenement" },
        { label: "Editer", active: true },
      ]
    },
    [URL_PHONE_CALL_LIST_PAGE]: {
      hasContainer: true,
      component: PhoneCallListRoute,
      path: URL_PHONE_CALL_LIST_PAGE,
      breadcrumb: [
        { label: "Phone" },
        { label: "Appels" },
        { label: "Lister", active: true },
      ]
    },
    [URL_PHONE_CALL_LIST_ID]: {
      hasContainer: true,
      component: PhoneCallListRoute,
      path: URL_PHONE_CALL_LIST_ID,
      breadcrumb: [
        { label: "Phone" },
        { label: "Appels" },
        { label: "Lister", active: true },
      ]
    },
    [URL_PHONE_CALL_LIST]: {
      hasContainer: true,
      component: PhoneCallListRoute,
      path: URL_PHONE_CALL_LIST,
      breadcrumb: [
        { label: "Phone" },
        { label: "Appels" },
        { label: "Lister", active: true },
      ]
    },

    [URL_PHONE_CONFIG_ID]: {
      hasContainer: true,
      component: PhoneConfigRoute,
      path: URL_PHONE_CONFIG_ID,
      breadcrumb: [
        { label: "Phone" },
        { label: "Liste des Hotels", link: URL_PHONE_CONFIG },
        { label: "Configuration", active: true },
      ]
    },
    [URL_PHONE_CONFIG]: {
      hasContainer: true,
      component: PhoneConfigRoute,
      breadcrumb: [
        { label: "Phone" },
        { label: "Configuration", active: true },
      ]
    },
    [URL_PHONE_DIRECTORY_ID]: {
      hasContainer: true,
      component: PhoneDirectoryRoute,
      breadcrumb: [
        { label: "Phone" },
        { label: "Liste des Hotels", link: URL_PHONE_DIRECTORY },
        { label: "Répertoire", active: true },
      ]
    },
    [URL_PHONE_DIRECTORY]: {
      hasContainer: true,
      component: PhoneDirectoryRoute,
      breadcrumb: [
        { label: "Phone" },
        { label: "Répertoire", active: true },
      ]
    },
    [URL_NETWORK_CONFIG_HOTELID]: {
      hasContainer: true,
      component: NetworkRouteHotel,
      breadcrumb: [
        { label: "Réseau" },
        { label: "Hotel", link: URL_NETWORK_CONFIG },
        { label: "Status", active: true },
      ]
    },
    [URL_NETWORK_CONFIG]: {
      hasContainer: true,
      component: NetworkRoute,
      breadcrumb: [
        { label: "Réseau" },
        { label: "Hotel", active: true },
      ]
    },
    [URL_PHONE_CALL_EDIT_PAGE]: {
      hasContainer: true,
      component: PhoneCallListRoute,
      breadcrumb: [
        { label: "Phone" },
        { label: "Appels" },
        { label: "Editer", active: true },
      ]
    },
    [URL_PHONE_CALL_EDIT]: {
      hasContainer: true,
      component: PhoneCallListRoute,
      breadcrumb: [
        { label: "Phone" },
        { label: "Appels" },
        { label: "Editer", active: true },
      ]
    },

    [URL_PLANNING_LIST]: {
      hasContainer: true,
      component: PlanningListRoute,
      breadcrumb: [
        { label: "Planning" },
        { label: "Liste", active: true },
      ]
    },
    [URL_CORP_LIST]: {
      hasContainer: true,
      component: CorpListRoute,
      breadcrumb: [
        { label: "Configuration" },
        { label: "Corp", active: true },
      ]
    },
    [URL_BACKOFFICE_BILLING_TOKEN_LIST]: {
      hasContainer: true,
      component: TokenListRoute,
      breadcrumb: [
        { label: "Paiement" },
        { label: "Empreintes Bancaires" },
        { label: "Lister", active: true },
      ]
    },
    // [URL_BACKOFFICE_BILLING_TOKEN_EDIT]: {
    //   hasContainer: true,
    //   component: TokenEditRoute,
    //   breadcrumb: [
    //     { label: "Paiement" },
    //     { label: "Empreintes Bancaires", link: URL_BACKOFFICE_BILLING_TOKEN_LIST },
    //     { label: "Editer", active: true },
    //   ]
    // },
    [URL_BACKOFFICE_BILLING_TOKEN_DISPLAY]: {
      hasContainer: true,
      component: TokenDisplayRoute,
      breadcrumb: [
        { label: "Paiement" },
        { label: "Empreintes Bancaires", link: URL_BACKOFFICE_BILLING_TOKEN_LIST },
        { label: "Afficher", active: true },
      ]
    },
    [URL_BACKOFFICE_BILLING_TOKEN_ADD]: {
      hasContainer: true,
      component: TokenAddRoute,
      breadcrumb: [
        { label: "Paiement" },
        { label: "Empreintes Bancaires", link: URL_BACKOFFICE_BILLING_TOKEN_LIST },
        { label: "Ajouter", active: true },
      ]
    },
    // [URL_BACKOFFICE_BILLING_TOKEN_EDIT]: {
    //   hasContainer: true,
    //   component: TokenEdit,
    //   breadcrumb: [
    //     { label: "Configuration" },
    //     { label: "Empreintes Bancaires", active: true },
    //   ]
    // },
  }
  // const [menuOpen, updateMenuOpen] = useState(false)
  const [alertContent, updateAlertContent] = useState<any>({
    message: <div />,
    duration: 2000,
    type: "",
    isVisible: false,
  })

  // console.log("PROPS", props)

  const handleAlert = (type: string, message: any, callback?: () => void) => {

    updateAlertContent({ type, message, isVisible: true, duration: alertContent.duration })
    setTimeout(() => {
      updateAlertContent({ type, message, isVisible: false, duration: alertContent.duration })
      callback && callback()
    }, alertContent.duration)
  }

  // return <AuthWrapper authType={AuthTypeEnum.REQUIRED} Component={() => <div className="app-content">
  return <div>
    <CTIAgent />

    <Route path={URL_VIDEO_CAMERA_DISPLAY} component={CameraDisplayRoute} />
    <AuthWrapper authType={AuthTypeEnum.REQUIRED}>
      <div className="app-content">

        <PhoneSipClientWrapper />

        <Menu />

        <alertContext.Provider value={{
          ...alertContent,
          setError: (message: any) => handleAlert("error", message),
          setMessage: (message: any, onClose) => handleAlert("message", message, onClose),
        }}>
          {/* <div onClick={() => menuOpen ? updateMenuOpen(false) : ""}> */}
          <div className="app-body">
            <Alert />
            <div className="container">
              <Breadcrumb itemMap={itemMap} />
            </div>

            <div>
              <Switch>

                {Object.keys(itemMap).map((key: string, index) => {
                  const value = itemMap[key]
                  const path = value?.path || key
                  return <BreadcrumbedComponent key={`breadcrumb_${key}_${index}`} path={path} component={value.component} hasContainer={value.hasContainer} />
                  // return <div key={key}>
                  //   <Route path={key} component={value.component} exact />
                  // </div>
                })}

                <Route path={URL_FRONT} component={MainRoute} exact />
                <Route path={URL_SEARCH} component={SearchRoute} exact />
                <Route path={URL_SEARCH_QUERY} component={SearchRoute} exact />

                {/* <Route path={URL_CUSTOMER_DETAIL} component={CustomerDetailsRoute} exact />
                <Route path={URL_CUSTOMER_LIST_PARAMS} component={CustomerListRoute} exact />
                <Route path={URL_CUSTOMER_LIST} component={CustomerListRoute} exact /> */}

                <Route path={URL_RESERVATION_SEARCH} component={ReservationSearchRoute} exact />
                <Route path={URL_POST_STAY_DASHBOARD} component={PostStayDashboardRoute} exact />
                <Route path={URL_POST_STAY_DASHBOARD_ID} component={PostStayDashboardRoute} exact />



                <Route path={URL_RESERVATION_DETAILS} component={ReservationDetailsRoute} exact />

                <Route path={URL_BACKOFFICE_USER_LIST} component={UserListRoute} exact />
                <Route path={URL_BACKOFFICE_USER_ADD} component={UserAddRoute} exact />



                <Route path={URL_CONFIRMATION_LIST} component={ConfirmationListRoute} exact />

              </Switch>
            </div>
            <Route path={URL_GRAPHQL} component={graphqlPlaygroundRoute} exact />
          </div>
        </alertContext.Provider>
        {/* </menuIsOpenContext.Provider> */}
        {/* </div>} /> */}
      </div>
    </AuthWrapper >
  </div >
}

const PublicContent = () => {

  const { i18n } = useTranslation()
  const location = useLocation();

  useEffect(() => {
    const lang = new URLSearchParams(location?.search)?.get("lang")
    lang && i18n.changeLanguage(lang);
    console.log("LANG", lang)
  }, [])

  return (
    <div className="app-public">
      <Switch>
        <Route path={URL_PUBLIC_PROPOSITION_DETAILS} component={PropositionPublicDetailsRoute} exact />
        <Route path={URL_PUBLIC_CONFIRMATION_DETAILS} component={ConfirmationPublicDetailsRoute} exact />
        <Route path={URL_PUBLIC_TOKEN_DISPLAY} component={TokenConfirmPublicIdAndSaltRoute} exact />
        <Route path={URL_PUBLIC_PAYMENT_3DSECURE} component={PaymentConfirmPublicIdAndSaltRoute} exact />
        <Route path={URL_PUBLIC_PAYMENT_3DSECURE_ID} component={PaymentConfirmIdRoute} exact />
        <Route path={URL_PUBLIC_PRE_STAY_ANSWER} component={PreStayAnswerRoute} exact />
        <Route path={URL_PUBLIC_POST_STAY_ANSWER} component={PostStayAnswerRoute} exact />
      </Switch>
    </div>
  )
}