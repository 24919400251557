import React, { useContext, useState } from "react"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"

// import './style.scss'
import { ReservationFullDetails } from "components/front/PMS/Reservation/Details/ReservationFullDetails"
import { Modal } from "components/common/Navigation/Modal/Modal"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"
import { userContext } from "context/User"
import { ContactEditButton } from "components/front/PMS/Contact/Widget/ContactEditButton"
// import {OverlayTrigger, Tooltip} from "react-bootstrap"

import { CursorInput, PostStay, PostStayListInput, PostStayResponseStatusEnum, PostStaySendStatusEnum, PostStayStatusEnum } from "model"
import { PostStayStatus } from "../Status/PostStayStatus"
import postStayListByCursorAndInputGql from "graphql/PMS/Stay/Post/query/postStayListByCursorAndInput.gql"
import { DateFormat, IconBookOpen, IconCheck, IconComment, IconEdit, IconEnvelope, IconSearch, IconTrash } from "@zipou/front_tools"
import { PostStayPreview } from "../Preview/PostStayPreview"
import { ReservationGroupDisplayButton } from "components/front/PMS/Reservation/Group/Display/ReservationGroupDisplayButton"
import postStayDeleteGql from "graphql/PMS/Stay/Post/mutation/postStayDelete.gql"
import { TemporisationButton } from "components/common/Navigation/Button/TemporisationButton"
import { PostStayStatusFilter } from "../Status/PostStayStatusFilter"
import { PostStaySendStatus } from "../Status/PostStaySendStatus"
import { PostStaySendStatusFilter } from "../Status/PostStaySendStatusFilter"
import { PostStayDisplay } from "../Display/PostStayDisplay"
import { PostStayResponseStatus } from "../Status/PostStayResponseStatus"
import { PostStayResponseStatusFilter } from "../Status/PostStayResponseStatusFilter"
import { URL_POST_STAY_DISPLAY_ID } from "constant/url"
import { Link } from "react-router-dom"

export const PostStayList = () => {

  const limit = 20;

  const user = useContext(userContext)
  const [reservationFocusId, updateReservationFocusId] = useState(null)
  const [focusPreview, updateFocusPreview] = useState<PostStay | null>(null)
  const [focusDisplay, updateFocusDisplay] = useState<PostStay | null>(null)

  const [focusHotelIds, updateFocusHotelIds] = useState(null)

  const [statusFilter, updateStatusFilter] = useState<PostStayStatusEnum[]>([PostStayStatusEnum.POST_STAY_STATUS_INITIAL, PostStayStatusEnum.POST_STAY_STATUS_ERROR, PostStayStatusEnum.POST_STAY_STATUS_DELETED])
  const [focusStatus, updateFocusStatus] = useState<any>(false)

  const [responseStatusFilter, updateResponseStatusFilter] = useState<PostStayResponseStatusEnum[]>([
    PostStayResponseStatusEnum.POST_STAY_RESPONSE_STATUS_ANSWERED,
    // PostStayResponseStatusEnum.POST_STAY_RESPONSE_STATUS_TO_ANSWER,
  ])
  const [focusResponseStatus, updateFocusResponseStatus] = useState<any>(false)

  const [sendStatusFilter, updateSendStatusFilter] = useState<PostStaySendStatusEnum[]>([PostStaySendStatusEnum.POST_STAY_SEND_STATUS_HOLD, PostStaySendStatusEnum.POST_STAY_SEND_STATUS_TODO])
  const [focusSendStatus, updateFocusSendStatus] = useState<any>(false)

  const [hotelIdsFilter, updateHotelIdsFilter] = useState<any>(user?.hotels)


  const [mutate] = useMutation(postStayDeleteGql)
  const isMulti = user?.hotels?.length > 1

  const { data, refetch, loading } = useQuery<{ list: { nodes: PostStay[], input: PostStayListInput, cursor: CursorInput } }>(postStayListByCursorAndInputGql, {
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: "cache-and-network",
    variables: {
      cursor: {
        page: 1,
        limit,
      },
      input: {
        orderField: "poststay.sendDateTarget",
        orderDirection: "DESC",
        status: statusFilter,
        sendStatus: sendStatusFilter,
        responseStatus: responseStatusFilter,
        hotelIdsList: hotelIdsFilter || [],
      }
    }
  })

  const onDelete = (postStay: PostStay) => {
    mutate({
      variables: {
        id: postStay?.id,
      },
      refetchQueries: [{
        query: postStayListByCursorAndInputGql,
        variables: {
          ...data?.list,
          cursor: {
            page: data?.list?.cursor?.page,
            limit: data?.list?.cursor?.limit,
          },
        }
      }]
    })
  }

  const postStay = data?.list

  const { nodes, cursor } = postStay || {}
  const hasResults = nodes?.length > 0

  return <div className="confirmation-list">

    {focusPreview && <Modal display={!!focusPreview} onClose={() => updateFocusPreview(null)}>
      <PostStayPreview postStayId={focusPreview?.id} />
    </Modal>}

    {focusDisplay && <Modal display={!!focusDisplay} onClose={() => updateFocusDisplay(null)}>
      <PostStayDisplay postStayId={focusDisplay?.id} />
    </Modal>}

    {focusStatus && <Modal display={true} onClose={() => updateFocusStatus(false)}>
      <PostStayStatusFilter value={statusFilter} onChange={(value) => {
        updateStatusFilter(value)
      }} />
    </Modal>}

    {focusSendStatus && <Modal display={true} onClose={() => updateFocusSendStatus(false)}>
      <PostStaySendStatusFilter value={sendStatusFilter} onChange={(value) => {
        updateSendStatusFilter(value)
      }} />
    </Modal>}

    {focusResponseStatus && <Modal display={true} onClose={() => updateFocusResponseStatus(false)}>
      <PostStayResponseStatusFilter value={responseStatusFilter} onChange={(value) => {
        updateResponseStatusFilter(value)
      }} />
    </Modal>}


    <Modal display={!!focusHotelIds} onClose={() => updateFocusHotelIds(null)}>
      <button className="btn btn-sm btn-warning" onClick={() => updateHotelIdsFilter([])}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => updateHotelIdsFilter(user?.Hotel?.map((h: any) => h.id))} style={{ marginLeft: 5 }}>Select All</button>
      <ul className="list-group">
        {user?.Hotel?.map((hotel: any) => {
          const isChecked = hotelIdsFilter?.includes(hotel?.id);
          return <li key={`hotel_filter_${hotel?.id}`} className="list-group-item" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input type="checkbox" checked={isChecked} id={`cb_${hotel?.id}`} onChange={(e: any) => updateHotelIdsFilter(e.target.checked ? [...(hotelIdsFilter || []), hotel?.id] : hotelIdsFilter?.filter((el: any) => el !== hotel?.id))}></input>
            <label htmlFor={`cb_${hotel?.id}`}><HotelBadge hotel={hotel} /></label>
          </li>
        })}
      </ul>
    </Modal>
    <Modal display={!!reservationFocusId} onClose={() => updateReservationFocusId(null)}>
      <ReservationFullDetails reservationId={reservationFocusId} />
    </Modal>
    {/* <Modal display={!!focusPmsId} onClose={() => updateFocusPmsId(null)}>
      <TextInput label="Numero de Résa" errors={null} value={pmsIdFilter} onChange={(e, v) => {
        updatePmsIdFilter(v)
      }} />
    </Modal> */}

    {(loading) && <div className="position-fixed" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div className="alert alert-warning">Chargement...</div>
    </div>}
    <div className="content">
      <table className="table table-stripped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            {isMulti && <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                updateFocusHotelIds(true)
              }}>
                Hotel
              </button>
            </th>}
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                // updateFocusPmsId(true)
              }}>
                Numéro de résa
              </button>
            </th>
            <th className="text-white">Contact</th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "poststay.creationDate",
                    hotelIdsList: hotelIdsFilter || [],
                  },
                })
              }}>
                Date de Création {data?.list?.input?.orderField === "poststay.creationDate" && <span className={(data?.list?.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "poststay.sendDateTarget",
                    hotelIdsList: hotelIdsFilter || [],
                  },
                })
              }}>
                Envoi prévu le {data?.list?.input?.orderField === "poststay.sendDateTarget" && <span className={(data?.list?.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>

            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => updateFocusStatus(true)}>
                Statut <span className="badge badge-sm badge-dark">{statusFilter?.length}</span>
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => updateFocusSendStatus(true)}>
                Validation <span className="badge badge-sm badge-dark">{sendStatusFilter?.length}</span>
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => updateFocusResponseStatus(true)}>
                Reponse <span className="badge badge-sm badge-dark">{responseStatusFilter?.length}</span>
              </button>
            </th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {(!hasResults && !loading) && <tr>
            <td colSpan={isMulti ? 8 : 7}><div className="alert alert-primary">Rien à afficher</div></td>
          </tr>}
          {hasResults && nodes?.map((postStay: PostStay) => {
            const { Hotel, Reservation, ReservationGroup, Contact, creationDate, sendDateTarget, responseStatus, AnswerList } = postStay

            const isGroup = !!ReservationGroup
            // const reservationLinkList = ReservationGroup?.ReservationGroupLink

            const hasAnswers = responseStatus === PostStayResponseStatusEnum.POST_STAY_RESPONSE_STATUS_ANSWERED

            return <tr key={`postStay_${postStay?.id}`}>
              {isMulti && <td><HotelBadge hotel={Hotel} /></td>}
              {!isGroup && <td><button className="brn btn-sm" onClick={() => updateReservationFocusId(Reservation?.id)}>{Reservation?.pmsId}</button></td>}
              {isGroup && <td>
                <div className="d-flex flex-row">
                  <ReservationGroupDisplayButton reservationGroup={postStay?.ReservationGroup} onDone={() => {
                    refetch({
                      ...data?.list?.cursor,
                      ...data?.list?.input,
                    })
                  }} />
                </div>
              </td>}
              <td>
                <ContactEditButton contact={Contact} />
                {/* <ContactDisplay contact={confirmation?.Contact} /> */}
              </td>
              <td>{creationDate && <DateTimeFormat value={new Date(creationDate)} />}</td>
              <td>{sendDateTarget && <DateFormat value={new Date(sendDateTarget)} />}</td>
              <td>
                <PostStayStatus status={postStay?.status as PostStayStatusEnum} />
              </td>
              <td>
                <PostStaySendStatus status={postStay?.sendStatus as PostStaySendStatusEnum} />
              </td>
              <td>
                <PostStayResponseStatus status={postStay?.responseStatus as PostStayResponseStatusEnum} />
              </td>
              <td className="">
                <div className="actions">
                  <button className="btn btn-sm btn-secondary text-white ml-1" onClick={() => updateFocusPreview(postStay)}>
                    <IconEnvelope style={{ fontSize: 13, color: "white" }} />
                  </button>
                  {hasAnswers && <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusDisplay(postStay)}>
                    <IconSearch />
                  </button>}
                  {/* {hasAnswers && <Link to={URL_POST_STAY_DISPLAY_ID.replace(":id", postStay?.id)}>
                    <button className="btn btn-sm btn-warning ml-1">
                      <IconSearch />
                    </button>
                  </Link>} */}
                  <TemporisationButton seconds={3} onClick={() => onDelete(postStay)}>
                    {(onclick, spinnerCompoment) => {
                      return <>
                        <button className="btn btn-sm btn-danger ml-1" style={{ whiteSpace: "nowrap" }} onClick={onclick}  >
                          {spinnerCompoment}
                          <IconTrash />
                        </button>
                      </>
                    }}
                  </TemporisationButton>
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    <div className="pagination-container">
      <Pagination cursor={cursor} onChange={(page: number) => {
        refetch({
          cursor: {
            page,
            limit,
          }
        })
      }} />
    </div>
  </div >
}